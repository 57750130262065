/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import styled, { ThemeProvider, createGlobalStyle } from "styled-components";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

import "fontsource-noto-sans";

import Header from "./header";
import Footer from "./footer";

gsap.registerPlugin(ScrollTrigger);
gsap.config({ force3D: true });

const theme = {
  colors: {
    primary: "#FFCC47",
    background: "#F4F4F4",
  },
  breakpoints: {
    tablet: "768px",
    desktop: "1024px",
  },
  sizing: {
    layoutMaxWidth: "1600px",
  },
};

const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
  }

  body {
    background-color: ${theme.colors.background};
    font-family: 'Noto Sans', sans-serif;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }
  
  a {
    color: inherit;
    text-decoration: inherit;
  }
  
  p {
    font-size: 0.875rem;
    margin: 1rem 0;
  }
  
  @media (min-width: ${theme.breakpoints.tablet}) {
    p {
      font-size: 1rem;
    }
  }
`;

const Container = styled.main`
  & > * {
    padding: 0 1rem;
    width: 100%;
    max-width: ${theme.sizing.layoutMaxWidth};
    margin: auto;
  }

  @media (min-width: ${props => props.theme.breakpoints.tablet}) {
    & > * {
      padding: 0 15%;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.desktop}) {
    & > * {
      padding: 0 5%;
    }
  }
`;

const Layout = ({ children }) => {
  return (
    <>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <Header />
        <Container>{children}</Container>
        <Footer />
      </ThemeProvider>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
