import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import gsap from "gsap";

const Container = styled.aside`
  padding: 6rem 1.5rem 1.5rem 1.5rem;
  width: 80%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: #ffffff;
  z-index: 50;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transform: translateX(-100%);

  @media (min-width: ${props => props.theme.breakpoints.tablet}) {
    display: none;
  }
`;

const NavList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const NavItem = styled.li`
  max-width: max-content;
  margin-bottom: 3rem;
`;

const NavLink = styled.a`
  position: relative;
  padding: 0.25rem;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 2px;
    background-color: ${props => props.theme.colors.primary};
    transition: transform 0.2s ease;
    transform: scaleX(0);
  }

  &:hover::after {
    transform: scaleX(1);
  }
`;

const ContactInfoList = styled.ul`
  list-style-type: none;
  font-size: 0.875rem;
  margin: 0;
  padding: 0;
`;

const ContactInfoItem = styled.li`
  margin-top: 1rem;
`;

const ContactInfoHeading = styled.h3`
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
  color: ${props => props.theme.colors.primary};
`;

const ContactInfoContent = styled.p`
  margin: 0;
`;

const MobileMenu = ({ navItems, isOpened }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          contact {
            address {
              label
              value
              link
            }
            mail {
              label
              value
            }
            phone {
              label
              value
            }
          }
        }
      }
    }
  `);

  const container = useRef(null);
  const navList = useRef(null);
  const contactInfoList = useRef(null);

  const [tl] = useState(gsap.timeline({ paused: true }));

  useEffect(() => {
    tl.fromTo(container.current, { x: "-100%" }, { x: 0, duration: 0.5 })
      .fromTo(
        navList.current.childNodes,
        { x: -50, opacity: 0 },
        { x: 0, opacity: 1, duration: 0.5, stagger: 0.1 }
      )
      .fromTo(
        contactInfoList.current.childNodes,
        { y: 50, opacity: 0 },
        { y: 0, opacity: 1, duration: 0.5, stagger: 0.1 },
        "-=0.5"
      );
  }, [tl]);

  useEffect(() => {
    isOpened ? tl.play() : tl.reverse();
  }, [isOpened, tl]);

  return (
    <Container ref={container}>
      <nav>
        <NavList ref={navList}>
          {navItems.map(navItem => (
            <NavItem key={navItem.id}>
              <NavLink as={AnchorLink} to={navItem.route}>
                {navItem.label}
              </NavLink>
            </NavItem>
          ))}
        </NavList>
      </nav>
      <ContactInfoList ref={contactInfoList}>
        <ContactInfoItem>
          <a
            href={`tel:${data.site.siteMetadata.contact.phone.value}`}
            aria-label={data.site.siteMetadata.contact.phone.label}
          >
            <ContactInfoHeading>
              {data.site.siteMetadata.contact.phone.label}
            </ContactInfoHeading>
            <ContactInfoContent>
              {data.site.siteMetadata.contact.phone.value}
            </ContactInfoContent>
          </a>
        </ContactInfoItem>
        <ContactInfoItem>
          <a
            href={data.site.siteMetadata.contact.address.link}
            aria-label={data.site.siteMetadata.contact.address.label}
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            <ContactInfoHeading>
              {data.site.siteMetadata.contact.address.label}
            </ContactInfoHeading>
            <ContactInfoContent>
              {data.site.siteMetadata.contact.address.value}
            </ContactInfoContent>
          </a>
        </ContactInfoItem>
        <ContactInfoItem>
          <a
            href={`mailto:${data.site.siteMetadata.contact.mail.value}`}
            aria-label={data.site.siteMetadata.contact.mail.label}
          >
            <ContactInfoHeading>
              {data.site.siteMetadata.contact.mail.label}
            </ContactInfoHeading>
            <ContactInfoContent>
              {data.site.siteMetadata.contact.mail.value}
            </ContactInfoContent>
          </a>
        </ContactInfoItem>
      </ContactInfoList>
    </Container>
  );
};

export default MobileMenu;
