import React, { useState } from "react";
import styled from "styled-components";
import { Sling as Hamburger } from "hamburger-react";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import logo from "../images/svg/logo.svg";

import MobileMenu from "./mobile-menu";

const Container = styled.header`
  height: 75px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background-color: #ffffff;
  z-index: 100;

  @media (min-width: ${props => props.theme.breakpoints.tablet}) {
    .hamburger-react {
      display: none;
    }
  }
`;

const Logo = styled.img`
  height: 75px;
`;

const Nav = styled.nav`
  display: none;
  width: 80%;
  max-width: 1024px;
  height: 100%;

  @media (min-width: ${props => props.theme.breakpoints.tablet}) {
    display: block;
  }
`;

const NavList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
`;

const NavItem = styled.li`
  height: 100%;
`;

const NavLink = styled.a`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  position: relative;

  &::before {
    content: "";
    width: 70%;
    height: 2px;
    background-color: ${props => props.theme.colors.primary};
    position: absolute;
    bottom: 5px;
    left: 15%;
    transition: transform 0.2s ease;
    transform: scaleX(0);
  }

  &:hover {
    &::before {
      transform: scaleX(1);
    }
  }
`;

const navItems = [
  {
    id: 1,
    route: "/#home",
    label: "Home",
  },
  {
    id: 2,
    route: "/#oferta",
    label: "Oferta",
  },
  {
    id: 3,
    route: "/o-nas",
    label: "O nas",
  },
  {
    id: 4,
    route: "/#kontakt",
    label: "Kontakt",
  },
];

const Header = () => {
  const [isOpened, setIsOpened] = useState(false);

  return (
    <>
      <Container>
        <Hamburger
          toggle={setIsOpened}
          toggled={isOpened}
          label="Otwórz lub zamknij menu"
        />
        <AnchorLink to="/#home" aria-label="Home">
          <Logo src={logo} alt="Logo GC Technik" />
        </AnchorLink>
        <Nav>
          <NavList>
            {navItems.map(navItem => (
              <NavItem key={navItem.id}>
                <NavLink as={AnchorLink} to={navItem.route}>
                  {navItem.label}
                </NavLink>
              </NavItem>
            ))}
          </NavList>
        </Nav>
      </Container>
      <MobileMenu navItems={navItems} isOpened={isOpened} />
    </>
  );
};

export default Header;
