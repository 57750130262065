import React, { useEffect, useMemo, useRef } from "react";
import styled from "styled-components";
import gsap from "gsap";

const Container = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #b3b3b3;
  font-size: 0.875rem;
  padding: 0 1rem;
  max-width: ${props => props.theme.sizing.layoutMaxWidth};
  margin: 3rem auto 1rem;

  @media (min-width: ${props => props.theme.breakpoints.tablet}) {
    padding: 0 15%;
  }

  @media (min-width: ${props => props.theme.breakpoints.desktop}) {
    flex-direction: row;
    justify-content: space-between;
    padding: 0 5%;
  }
`;

const Text = styled.p`
  margin: 0.5rem 0;
  padding: 0;
`;

const Footer = () => {
  const container = useRef(null);

  useEffect(() => {
    gsap.set(container.current.children, { opacity: 0 });

    gsap.fromTo(
      container.current.children,
      { y: 100, opacity: 0 },
      {
        y: 0,
        opacity: 1,
        duration: 1,
        stagger: 0.5,
        scrollTrigger: container.current,
      }
    );
  }, []);

  const currentYear = useMemo(() => {
    return new Date().getFullYear();
  }, []);

  return (
    <Container ref={container}>
      <Text>&copy; {currentYear} GC Technik, Wszelkie prawa zastrzeżone.</Text>
      <Text>&copy; {currentYear} Stworzone przez Infoserwis.</Text>
    </Container>
  );
};

export default Footer;
