import React from "react";
import styled from "styled-components";
import Icon from "../../components/icon";

const Svg = styled(Icon)`
  fill: #ffcc47;
`;

const Phone = ({ className }) => {
  return (
    <Svg viewBox="0 0 28 43" className={className}>
      <path d="M24.8889 0H3.11111C2.28599 0 1.49467 0.323596 0.911224 0.8996C0.327777 1.47561 0 2.25683 0 3.07143V39.9286C0 40.7432 0.327777 41.5244 0.911224 42.1004C1.49467 42.6764 2.28599 43 3.11111 43H24.8889C25.714 43 26.5053 42.6764 27.0888 42.1004C27.6722 41.5244 28 40.7432 28 39.9286V3.07143C28 2.25683 27.6722 1.47561 27.0888 0.8996C26.5053 0.323596 25.714 0 24.8889 0ZM15.5556 39.9286H12.4444V36.8571H15.5556V39.9286ZM3.11111 33.7857V3.07143H24.8889V33.7857H3.11111Z" />
    </Svg>
  );
};

export default Phone;
