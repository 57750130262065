import React from "react";
import styled from "styled-components";
import Icon from "../../components/icon";

const Svg = styled(Icon)`
  fill: #ffcc47;
`;

const Mark = ({ className }) => {
  return (
    <Svg viewBox="0 0 44 55" className={className}>
      <path d="M21.9998 32.9993C28.0662 32.9993 32.9996 28.0658 32.9996 21.9994C32.9996 15.933 28.0662 10.9995 21.9998 10.9995C15.9333 10.9995 10.9999 15.933 10.9999 21.9994C10.9999 28.0658 15.9333 32.9993 21.9998 32.9993ZM21.9998 16.4995C25.033 16.4995 27.4997 18.9662 27.4997 21.9994C27.4997 25.0326 25.033 27.4993 21.9998 27.4993C18.9665 27.4993 16.4998 25.0326 16.4998 21.9994C16.4998 18.9662 18.9665 16.4995 21.9998 16.4995Z" />
      <path d="M20.405 54.4879C20.8704 54.8203 21.4281 54.999 22 54.999C22.5719 54.999 23.1296 54.8203 23.595 54.4879C24.431 53.8966 44.0795 39.7096 43.9998 21.9998C43.9998 9.86964 34.1301 0 22 0C9.86988 0 0.000242101 9.86964 0.000242101 21.986C-0.079507 39.7096 19.569 53.8966 20.405 54.4879ZM22 5.49994C31.0997 5.49994 38.4998 12.9001 38.4998 22.0135C38.5576 34.2179 26.433 45.1765 22 48.7707C17.5698 45.1738 5.44243 34.2124 5.50018 21.9998C5.50018 12.9001 12.9004 5.49994 22 5.49994Z" />
    </Svg>
  );
};

export default Mark;
