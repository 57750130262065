import React from "react";
import styled from "styled-components";
import Icon from "../../components/icon";

const Svg = styled(Icon)`
  fill: #ffcc47;
`;

const Mail = ({ className }) => {
  return (
    <Svg viewBox="0 0 43 28" className={className}>
      <path d="M1.37123 3.00067L19.2879 12.4017C19.8899 12.7167 20.6687 12.866 21.4522 12.866C22.2358 12.866 23.0146 12.7167 23.6166 12.4017L41.5332 3.00067C42.7014 2.387 43.805 0 41.6622 0H1.24461C-0.898218 0 0.205448 2.387 1.37123 3.00067ZM42.0755 8.141L23.6166 17.5373C22.8043 17.9527 22.2358 18.0017 21.4522 18.0017C20.6687 18.0017 20.1001 17.9527 19.2879 17.5373C18.4757 17.122 2.24795 8.813 0.922115 8.13867C-0.00955159 7.66267 3.86059e-06 8.22033 3.86059e-06 8.64967V25.6667C3.86059e-06 26.6467 1.35211 28 2.38889 28H40.6111C41.6479 28 43 26.6467 43 25.6667V8.652C43 8.22267 43.0096 7.665 42.0755 8.141Z" />
    </Svg>
  );
};

export default Mail;
